import Alpine from 'alpinejs';

Alpine.store('cart', {

    message: null,
    messageStatus: null,

    cart: {
        lineItems: [],
        itemSubtotalAsCurrency: null,
    },

    getCart() {
        fetch(`/actions/commerce/cart/get-cart`, {
            headers: { Accept: "application/json" },
        })
        .then((response) => { 
            return response.ok ? response.json() : Promise.reject(response);
        })
        .then((json) => this.setCart(json.cart))
        .catch((response) => {
            response.json().then((json) => {
                this.setCart(json.cart);
            })
        });

        return this.cart;
    },

    setCart(cart) {
        this.cart = cart;
    },

    addToCart() {
        this.submitForm();
    },

    removeLineItem(lineItem) {
        //
    },

    updateLineItem(lineItem, qty) {
        //
    },
});