import Alpine from 'alpinejs';

Alpine.data('purchasable', () => ({

    data: {},
    is_loading: false,

    errors: [],

    addToCart() {
        let formData = new FormData(this.$el.closest("form"));
        this._submitForm(formData);
    },

    _submitForm(formData) {
        this.is_loading = true;
        fetch('', {
            method: "POST",
            headers: { Accept: "application/json" },
            body: formData,
        })
        .then((response) => { 
            if (response.ok) { return response.json(); }
            return Promise.reject(response);
        })
        .then((json) => {
            Alpine.store('cart').setCart(json.cart);
            //window.Fancybox.close();
            //$dispatch('closepopup');
            window.dispatchEvent(new CustomEvent('closepopup'));
            window.dispatchEvent(new CustomEvent('minicart:toggle'));
        })
        .catch((response) => {
            response.json().then((json) => {
                Alpine.store('cart').getCart();
                // todo: error message
                Alpine.store('flash').setFlash('error', json.message);
            })
        })
        .finally(() => {
            this.is_loading = false;
        });
    },
}));