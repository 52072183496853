import "@css/app.pcss";

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
Alpine.plugin(persist)
window.Alpine = Alpine;

import '@js/components/account';
import '@js/components/flash';

import '@js/components/cart/cart';
import '@js/components/cart/cartLineItem';
import '@js/components/cart/purchasable';

import '@js/components/qty';
import '@js/components/variant';

import '@js/components/minicart';
import '@js/components/miniaccount';

import '@js/components/filter';

Alpine.start();

import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

const main = async () => {
  const lazysizes = await import(/* webpackChunkName: "lazysizes" */'lazysizes');
  return null;
};


window.loadSliders =()=>{
var swiper = new Swiper(".thumbnail", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var productSlider = new Swiper(".productSlider", {
    loop: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });
  var topbar = new Swiper(".topbar", {
    loop: true,
    slidesPerView: 1,
    enabled: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  });
  var topbar = new Swiper(".topbar", {
    loop: true,
    slidesPerView: 1,
    enabled: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  });
  var header = new Swiper(".header", {
    slidesPerView: 1.2,
    spaceBetween: 20,
    loop: true,
    breakpoints: {
      640: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-previous",
    },
  });
  var entrepreneurs = new Swiper(".entrepreneurs", {
    slidesPerView: 1.2,
    spaceBetween: 20,
    loop: true,
    breakpoints: {
      640: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-previous",
    },
  });
}

// Execute async function
main().then( (root) => {
  // sliders()
  window.loadSliders();

  // Hide all the placeholder images when lazyload image is unveiled (also works with AJAX loaded content)
  document.addEventListener('lazyloaded', function(e){
      const imageThatJustLoaded = e.target;
      if (imageThatJustLoaded.previousSibling && imageThatJustLoaded.previousSibling.classList.contains('placeholder')) {
          imageThatJustLoaded.previousSibling.classList.add('opacity-0');
      }
  });
});

$(".card--cart__info__remove, .card--cart__info__qty select").change(function(){

  $(".component--cart__overview").submit();

});

$("#cart__afhaallocatie, #cart__afhaaldatum").change(function(){

  $(".component--cart__overview").submit();

});