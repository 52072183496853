import Alpine from 'alpinejs';

Alpine.data('qty', () => ({

    qty: 1,
    minQty: 1,
    maxQty: 9999,
    qtySteps: 1,
    qtyRounding: 1,
    
    getQtySteps() {
        return this.qtySteps;
    },

    getQtyRounding() {
        return this.qtyRounding;
    },

    min() {
        this.updateQty('min');
    },

    plus() {
        this.updateQty('plus');
    },

    updateQty(type) {
        let currentQty = this.qty;
        let qty = this.qty;

        if (typeof(type) != 'undefined') {
            qty = type == 'min' ? (this.qty - this.getQtySteps()) : (this.qty + this.getQtySteps());
        }

        if (this.getQtyRounding() > 1) {
			if (type == 'min') {
				qty = Math.floor(qty / this.getQtyRounding()) * this.getQtyRounding();
			} else {
				qty = Math.ceil(qty / this.getQtyRounding()) * this.getQtyRounding();
			}
		}

        qty = parseInt(qty);
        let minQty = !isNaN(parseInt(this.minQty)) ? parseInt(this.minQty) : parseInt(1);
		let maxQty = !isNaN(parseInt(this.maxQty)) ? parseInt(this.maxQty) : parseInt(9999);
        
        if (qty >= minQty && qty <= maxQty) {
            this.qty = qty;
		}

        if (qty < minQty) { this.qty = minQty; }
		if (qty > maxQty) { this.qty = maxQty; }

        if (this.qty != currentQty) {
            if (typeof this.update !== "undefined") { 
                this.$nextTick(() => { this.update() });
            }
        }
        return;
    },
}));