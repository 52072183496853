import Alpine from 'alpinejs';

Alpine.data('account', () => ({

    is_user: false,
    is_forgotPassword: false,
    is_loading: false,
    errorMessage: '',
    successMessage: '',

    data: {},

    submit() {
        let formData = new FormData(this.$el.closest("form"));
        this._submitForm(formData);
    },

    _submitForm(formData) {
        this.error = '';
        this.is_loading = true;
        fetch('', {
            method: "POST",
            headers: { Accept: "application/json" },
            body: formData,
        })
        .then((response) => { 
            if (response.ok) { return response.json(); }
            return Promise.reject(response);
        })
        .then((json) => {
            if (typeof(json.redirect) != 'undefined') {
                window.location.href = json.redirect;
                return null;
            }

            if (typeof(json.message) != 'undefined') {
                this.successMessage = json.message;
            }
        })
        .catch((response) => {
            response.json().then((json) => {
                console.log(json);
                this.errorMessage = json.message;
            })
        })
        .finally(() => {
            this.is_loading = false;
        });
    },

    switchToLogin() {
        this.errorMessage = '';
        this.successMessage = '';
        this.is_forgotPassword = false;
    },

    switchToForgotPassword() {
        this.errorMessage = '';
        this.successMessage = '';
        this.is_forgotPassword = true;
    },
}));