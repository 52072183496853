import Alpine from 'alpinejs';

Alpine.data('miniaccount', () => ({

    is_open: false,
    
    init() {
        var self = this;
        window.addEventListener('miniaccount:toggle', () => self.toggle());
        window.addEventListener('miniaccount:open', () => self.open());
    },

    toggle() {
        this.is_open ? this.close() : this.open();
    },

    open() {
        this.is_open = true;
        document.body.classList.add('overflow-hidden');
    },

    close() {
        this.is_open = false;
        document.body.classList.remove('overflow-hidden');
    }
}));