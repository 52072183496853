import Alpine from 'alpinejs';

Alpine.data('cartLineItem', () => ({

    is_loading: false,

    log() {
        console.log(this.lineItem.qty);
    },

    getQtySteps() {
        return 1;
    },

    getQtyRounding() {
        return 1;
    },

    min() {
        this.updateQty('min');
    },

    plus() {
        this.updateQty('plus');
    },

    updateQty(type) {
        let currentQty = this.lineItem.qty;
        let qty = this.lineItem.qty;
        if (typeof(type) != 'undefined') {
            qty = type == 'min' ? (this.lineItem.qty - this.getQtySteps()) : (this.lineItem.qty + this.getQtySteps());
        }

        if (this.getQtyRounding() > 1) {
			if (type == 'min') {
				qty = Math.floor(qty / this.getQtyRounding()) * this.getQtyRounding();
			} else {
				qty = Math.ceil(qty / this.getQtyRounding()) * this.getQtyRounding();
			}
		}

        qty = parseInt(qty);
        let minQty = !isNaN(parseInt(this.lineItem.snapshot.minQty)) ? parseInt(this.lineItem.snapshot.minQty) : parseInt(1);
		let maxQty = !isNaN(parseInt(this.lineItem.snapshot.maxQty)) ? parseInt(this.lineItem.snapshot.maxQty) : parseInt(9999);
        
        if (qty >= minQty && qty <= maxQty) {
            this.lineItem.qty = qty;
		}

        if (qty < minQty) { this.lineItem.qty = minQty; }
		if (qty > maxQty) { this.lineItem.qty = maxQty; }

        if (this.lineItem.qty != currentQty) {
            this.$nextTick(() => { this.update() });
        }
        return;
    },

    update() {
        let formData = new FormData(this.$el.closest("form"));
        this._submitForm(formData);
    },

    remove(lineItem) {
        let formData = new FormData(this.$el.closest("form"));
        formData.append(`lineItems[${lineItem.id}][remove]`, '1');
        this._submitForm(formData);
    },

    _submitForm(formData) {
        this.is_loading = true;

        fetch('', {
            method: "POST",
            headers: { Accept: "application/json" },
            body: formData,
        })
        .then((response) => { 
            if (response.ok) { return response.json(); }
            return Promise.reject(response);
        })
        .then((json) => {
            if (!json.success) {
                Alpine.store('cart').getCart();
                Alpine.store('flash').setFlash('error', json.message);
            } else {
                Alpine.store('cart').setCart(json.cart);
            }
        })
        .catch((response) => {
            response.json().then((json) => {
                Alpine.store('cart').getCart();
                Alpine.store('flash').setFlash('error', json.message);
            })
        })
        .finally(() => {
            this.is_loading = false;
        });
    }
}));