import Alpine from 'alpinejs';

Alpine.data('minicart', () => ({

    is_open: false,
    
    init() {
        var self = this;
        window.addEventListener('minicart:toggle', () => self.toggle());
        window.addEventListener('minicart:open', () => self.open());
        Alpine.store('cart').getCart();
    },

    toggle() {
        this.is_open ? this.close() : this.open();
    },

    open() {
        this.is_open = true;
        document.body.classList.add('overflow-hidden');
    },

    close() {
        this.is_open = false;
        document.body.classList.remove('overflow-hidden');
    }
}));