import Alpine from 'alpinejs';

Alpine.store('flash', {
    message: null,
    messageStatus: null,
    timeout: null,

    setFlash(status, message) {
        console.log(message);
        this.messageStatus = status;
        this.message = message;
        this.show();
    },

    showEvent: null,
    show() { this.showEvent = Date.now() },
});

Alpine.data('flash', () => ({

    is_open: false,
    
    init() {
        this.$watch('$store.flash.showEvent', v => this.show());
    },

    show() {
        clearTimeout(this.timeout);
        this.is_open = true;
        this.timeout = setTimeout(() => this.is_open = false, 2000);
    },
}));