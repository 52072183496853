import Alpine from 'alpinejs';

Alpine.data('variant', () => ({
    
    init() {
        var self = this;
    },

    variantChange() {
        let variantId = this.$el.value;

        fetch(`/ajax/variant/${variantId}.json`, {
            headers: { Accept: "application/json" },
        })
        .then((response) => { 
            return response.ok ? response.json() : Promise.reject(response);
        })
        .then((json) => {
            document.querySelector('#product-variant-details').innerHTML = json.details;
            document.querySelector('#product-variant-images').innerHTML = json.images;
            document.querySelector('#product-variant-image').innerHTML = json.image;
        })
        .catch((response) => {
            response.json().then((json) => {
                console.error(json);
            })
        });
    }
}));