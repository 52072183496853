import Alpine from 'alpinejs';

Alpine.store('filter', {

    categoryId: null,
    pageId: null,
    siteId: null,
    
    filters: [],
    products: [],
    productsCount: 0,
    is_loading: false,

    sort: 'random',

    setSort() {
        this.sort = this.$el.value;
    },

    getFilter() {
        if (!this.is_loading && (this.categoryId != null || this.pageId != null)) {
            this.is_loading = true;
            let queryString = decodeURIComponent(this.getFilterParams(true).toString());
            console.log(this.sort);
            console.log(queryString);
            fetch(`/actions/brik/filter?${queryString}`, {
                headers: { Accept: "application/json" },
            })
            .then((response) => { 
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((json) => {
                this.filters = json.filters;
                this.productsCount = json.products.count;
                document.querySelector('#filter-products').innerHTML = json.products.html;
                window.scrollTo(0, 0);
                document.querySelector('#filters').scrollTo(0,0);
            })
            .catch((response) => {
                response.json().then((json) => {
                    console.error(json);
                })
            })
            .finally(() => {
                this.updateQueryString();
                this.is_loading = false;
            });
        }

        return this.filters;
    },

    updateQueryString() {
        let queryString = decodeURIComponent(this.getFilterParams().toString());
        var pageUrl = '?' + queryString;
        window.history.replaceState('', '', pageUrl);
    },

    getFilterParams(addExtraParams) {

        let params = new URLSearchParams();
        this.filters.forEach(filter => {
            if (filter.selected.length > 0) {
                params.set(filter.key, filter.selected.toString());
            }
        });

        if (typeof(addExtraParams) != 'undefined') {
            params.set('category', this.categoryId);
            params.set('page', this.pageId);
            params.set('site', this.siteId);
            params.set('sort', this.sort);
        }

        return params;
    },

    hasVisibleOptions(filter) {
        let hasOptions = false;
        filter.options.forEach(option => {
            if (option.count > 0) {
                hasOptions = true;
            }
        });

        return hasOptions;
    }
});

Alpine.data('filter', () => ({
    
    is_open: false,
    collapsedFilters: [],

    toggleCollapsedFilter(key) {
        if (this.collapsedFilters.indexOf(key) >= 0) {
            this.collapsedFilters.splice(this.collapsedFilters.indexOf(key), 1);
        } else {
            this.collapsedFilters.push(key);
        }
    },

    isFilterCollapsed(key) {
        return (this.collapsedFilters.indexOf(key) >=0 );
    },

    init() {
        var self = this;
        window.addEventListener('filter:toggle', () => self.toggle());
        window.addEventListener('filter:open', () => self.open());
    },

    toggle() {
        this.is_open ? this.close() : this.open();
    },

    open() {
        this.is_open = true;
        document.body.classList.add('overflow-hidden');
    },

    close() {
        this.is_open = false;
        document.body.classList.remove('overflow-hidden');
    },

    loadFilters(categoryId, siteId) {
        Alpine.store('filter').categoryId = categoryId;
        Alpine.store('filter').siteId = siteId;
        Alpine.store('filter').getFilter();
    },

    loadFiltersForPage(pageId, siteId) {
        Alpine.store('filter').pageId = pageId;
        Alpine.store('filter').siteId = siteId;
        Alpine.store('filter').getFilter();
    },

    update() {
        this.$nextTick(() => { 
            Alpine.store('filter').getFilter();
        });
    }
}));